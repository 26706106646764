import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: async () => import("@/views/Main.vue"),
    },
    {
      path: "/help",
      name: "Help",
      component: async () => import("@/views/Help.vue"),
    },
    {
      path: "/test",
      name: "Test",
      component: async () => import("@/views/TestUserSystem.vue"),
    },
    {
      path: "/confetti",
      name: "Confetti Test",
      component: async () => import("@/components/user/Confetti.vue"),
    },
  ],
});

export default router;