<template>

  <router-view></router-view>

</template>

<script>

export default {
  name: 'MLS App'
}

</script>

<style lang="scss">

// import required libraries
@import 'https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.4/css/fontawesome.min.css';

// import app styles
@import "@/assets/css/app.scss";

/* keep this: works as conditional to hide index.html error display */
.vue-failed {
  display: none;
}

</style>