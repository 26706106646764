import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import { createPinia } from 'pinia'

//import { clickOutside } from '@/directives/clickOutside';

try {

  const pinia = createPinia();
  const app = createApp( App );

  // add plugins
  app.use(router);
  app.use(pinia);

  // add directives 
  // app.directive( 'click-outside', clickOutside );

  app.mount('#app');

  // // remove loading animation container
  document.querySelector('.vue-loading').remove();
  // @NOTE: moved loading removal to Main component

  // remove vue failed to clean up DOM
  document.querySelector('.vue-failed').remove();

} catch( e ){

  console.log( 'Vue init error:', e );

  // show vue failed error if vue did not load properly
  document.querySelector('.vue-failed').style.display = 'block';

}